<template>
    <div class="form-template">
        <div class="submission-form">
            <a v-if="!isActive" href="#" @click.prevent="isActive=true" class="button">Click here to submit a case study</a>
            <template v-else>
               <!--  <input type="text" >
                <section
                    v-for="filter in reorderedFilters"
                    :key="filter.name"
                    class="filter"
                >
                <p class="filter-title">{{filter.name}}</p>
                <div class="term" v-for="term in filter.terms" :key="term.slug">
                    <input type="checkbox" v-model="payload[filter.slug]" :id="`submission-${term.slug}`" :value="term.term_id"> 
                    <label :for="`submission-${term.slug}`" v-html="term.name"></label>
                </div>
                </section> -->
                <p>All fields are required</p>
                <div class="row">
                    <div class="columns medium-4 small-12">
                        <input v-model="payload.fullName" type="text" placeholder="Full Name" required>
                    </div>
                    <div class="columns medium-4 small-12">
                        <input v-model="payload.email" type="email" placeholder="Email" required>
                    </div>
                    <div class="columns medium-4 small-12">
                        <input v-model="payload.org" type="text" placeholder="Name of Initiative" required>
                    </div>
                    <div class="columns small-12">
                        <textarea v-model="payload.projectDescription" cols="30" rows="10" placeholder="Description"></textarea>
                    </div>
                    <div class="columns medium-12">
                        <button @click.prevent="sendMail" :disabled="!isValid" class="button">Submit</button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
    import {MAP_STYLES} from "./constants.js";
    const LINK = "/sender";
    

    export default {
    data() {
        return {
            isActive: false,
            
            payload: {
                fullName: "",
                email: "",
                projectDescription: "",
                org: "",
                id: "s45fgty6787uieocjdudg"
            },
            filters: null,
        }
    },
    mounted() {
      this.filters = JSON.parse(localStorage.getItem("filters"));
    },
    methods: {
        async sendMail() {
            let url = `${LINK}?uemail=${this.payload.email}&uname=${this.payload.fullName}&unotes=${this.payload.projectDescription}&uorg=${this.payload.org}&uid=${this.payload.id}`;
            try {
                let response = await fetch(url, {
                    method: "GET",
                    headers: {"Content-type": "application/x-www-form-urlencoded"}
                });
                console.log(response);

                if (response.ok) {
                    let responseJson = await response.json();
                    console.log(responseJson);

                    if (responseJson.success) {
                        this.payload.fullName = "";
                        this.payload.projectDescription = "";
                        this.payload.email = "";
                        this.payload.org = "";

                        alert("Thank you for your submission! We will contact you soon.");
                    } else {
                        this.sendingError();
                    }
                }

                if (!response.ok || response.status == 401) {
                    this.sendingError();
                }
            } catch (error) {
                console.error(error);
                this.sendingError();
            }
        },
        sendingError() {
            alert("Sorry, there was a problem sending your submission. Please try again or email us: info@rightscolab.org");
        },
    },
    computed: {
        isValid() {
            return (
                this.payload.fullName !== "" &&  
                this.payload.email !== "" &&  
                this.payload.projectDescription !== ""  && 
                this.payload.org !== ""  
            );
        },
        // reorderedFilters() {
        //     const arr = [];

        //     if(this.filters) {
        //         arr.push(this.filters[1]);
        //         arr.push(this.filters[2]);
        //         arr.push(this.filters[3]);
        //         arr.push(this.filters[4]);
        //         arr.push(this.filters[0]);
        //     }

        //     return arr;
        // }
    }
}
</script>