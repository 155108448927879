<template>
    <div class="map-template">
        <div class="row full-width">
          <div class="small-12 medium-8 large-9 columns">
             <div class="relative-scope" >
                <GmapMap
                  :center="{lat:31.196100, lng:16.583862}"
                  :zoom="2"
                  map-type-id="terrain"
                  width="100%"
                  style="width: 100%; height: 80vh;"
                  :options="mapOptions"
                >
                  <GmapCluster :calculator="this.calculateClusterMarker" :gridSize="10" :styles="this.clusterStyles()" :zoomOnClick="true">
                    <GmapMarker
                      :key="index"
                      v-for="(m, index) in filteredMapItems"
                      :position="m.position"
                      :clickable="true"
                      :draggable="false"
                      :icon="getIcon(m)"
                      @click="handleMarker(m)"
                    />
                  </GmapCluster>
                </GmapMap>
                <div class="overlay" v-if="selectedItem">
                    <a href="#" @click.prevent="handleClose" class="close">&times;</a>
                
                    <h4 v-html="selectedItem.title"></h4>
                    <div class="meta map-options-title">
                        {{selectedItem.city}}, {{selectedItem.country}}
                    </div>
                    <img
                      :alt="selectedItem.title"
                      :src="selectedItem.thumbnail"
                      v-if="selectedItem.thumbnail"
                    />
                    <p class="small" v-if="stripHtml(selectedItem.coronavirus_response_description)" v-html="selectedItem.coronavirus_response_description">
                    <p class="small" v-else v-html="selectedItem.narrative"></p>
                    <p class="small"><span class="caps"><strong>Organizational Form</strong></span>: <span v-html="selectedItemOrgNames.join(', ')"></span></p>
                    <a href="#case-study" @click="handleDetails" class="button secondary">Details</a>
                </div>
            </div>
          </div>

          <div class="small-12 medium-4 large-3 columns">
            <div class="filters">
                <h6 style="padding-left:1rem" v-if="hasAnyFilters" class="filter-title flex flex-aicenter" @click="clearAll">
                    <i class="material-icons">close</i>
                    <span>Clear All Filters ({{filterCount}})</span>
                </h6>
                <h6 style="padding-left:1rem" v-else class="filter-title flex flex-aicenter">
                    <span>Filters</span>
                </h6>
                <section
                    v-for="(filter, index) in reorderedFilters"
                    :key="filter.name+index"
                    class="filter"
                >
                    <h6 
                        class="map-options-title flex flex-aicenter map-options-title-filter map-options-title-filter-normal"
                        v-bind:class="{ 'map-options-title-filter-coronavirus': filter.slug === 'coronavirus_response' }"
                        @click.prevent="handleFilterClick(index)"
                    >
                        <template v-if="filter.isVisible"><i class="material-icons">remove</i></template>
                        <template v-else><i class="material-icons">add</i></template>
                        <span>{{filter.name}}</span>
                    </h6>
                    <div class="hidden" v-if="filter.isVisible"> 
                        <div class="term" v-for="term in filter.terms" :key="term.slug">
                            <input type="checkbox" v-model="selectedFilters[filter.slug]" :id="term.slug" :value="term.slug"> 
                            <label :for="term.slug" v-html="term.name"></label>
                        </div>
                    </div>
                </section>
            </div>
          </div>
        </div>
        <div id="case-study" class="row full-width-inner-content">
          <div class="flex flex-nowrap" v-if="isDetails && selectedItem">
            <div class="pull copy">
                <h2 v-html="selectedItem.title"></h2>
                <p v-html="selectedItem.narrative_full"></p>
                <div v-if="stripHtml(selectedItem.coronavirus_response_description_full)">
                  <h3 class="map-options-title">COVID-19 Response</h3>
                  <p v-html="selectedItem.coronavirus_response_description_full"></p>
                </div>
                <h3 class="map-options-title">Theory of Change</h3>
                <p v-html="selectedItem.theory_of_change"></p>
                <h3 class="map-options-title">Activities</h3>
                <p v-html="selectedItem.activities"></p>
                <h3 class="map-options-title">Results</h3>
                <p v-html="selectedItem.results"></p>
            </div>
            <div class="pull meta intro" style="order: 0">
                <ul>
                    <li>
                        <h3 class="map-options-title">Location</h3>
                        <div>
                            <h6>{{ selectedItem.city }}, {{ selectedItem.country }}</h6>
                        </div>
                    </li>
                    <li v-if="selectedItem.website">
                        <h3 class="map-options-title">Website</h3>
                        <div>
                            <h6><a :href="selectedItem.website" target="_blank" class="href">Visit now</a></h6>
                        </div>
                    </li>
                    <li v-if="selectedItem.facebook || selectedItem.twitter || selectedItem.linkedin">
                        <h3 class="map-options-title">Social Media</h3>
                        <div v-if="selectedItem.facebook">
                            <h6><a :href="'https://facebook.com/' + selectedItem.facebook" target="_blank" class="href">Facebook</a></h6>
                        </div>
                        <div v-if="selectedItem.instagram">
                            <h6><a :href="'https://instagram.com/' + selectedItem.instagram" target="_blank" class="href">Instagram</a></h6>
                        </div>
                        <div v-if="selectedItem.twitter">
                            <h6><a :href="'https://twitter.com/' + selectedItem.twitter" target="_blank" class="href">Twitter</a></h6>
                        </div>
                        <div v-if="selectedItem.linkedin">
                            <h6><a :href="'https://www.linkedin.com/company/' + selectedItem.linkedin" target="_blank" class="href">LinkedIn</a></h6>
                        </div>
                    </li>
                    <li v-if="selectedItem.founded_at">
                        <h3 class="map-options-title">Year of Founding</h3>
                        <div>
                            <h6>{{ selectedItem.founded_at }}</h6>
                        </div>    
                    </li>
                    <li v-if="selectedItem.profile">
                        <h3 class="map-options-title">Profile</h3>
                        <div>
                            <h6><a target="_blank" :href="selectedItem.profile">Read article</a></h6>
                        </div>    
                    </li>
                   <template v-if="allTerms" v-for="(item,index) in allTerms">
                        <li v-if="item.length > 0">
                            <h3 class="map-options-title">{{taxLabels[index]}}</h3>
                            <span class="" v-for="(term,indx) in item">
                            <span class="" v-html="term"></span><span v-if="indx < item.length - 1">, </span>
                            </span>
                        </li>
                   </template>
                     <li>
                        <h3 class="map-options-title">Share This Case Study</h3>
                        <div>
                            <h6><a target="_blank" :href="selectedItem.link" v-html="selectedItem.title"></a></h6>
                        </div>    
                    </li>
                </ul>
                <a href="#map" class="button" onclick="document.querySelector('#map').scrollIntoView({ behavior: 'smooth', block: 'start' }); return false;">Back to the map</a>
            </div>
        </div>

    </div>
    </div>
</template>
<script>
import { MAP_STYLES } from "./constants.js";

const API_BASE = `https://${window.location.hostname}/wp-json/`;
const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sYXJhLnRlc3QiLCJpYXQiOjE1NzY2OTA4NjcsIm5iZiI6MTU3NjY5MDg2NywiZXhwIjoxNTc3Mjk1NjY3LCJkYXRhIjp7InVzZXIiOnsiaWQiOiIxIn19fQ.xClEsFB9mt4pMpbZIk0EQ-CEezwAZ_ymlL4JaoYOENg";
const MARKER_PREFIX = 'marker_';
const MARKERS = [
  'normal',
  'coronavirus',
  'coronavirus_normal',
];
    
export default {
    data() {
        return {
            mapItems: [],
            mapOptions: {
                styles: MAP_STYLES
            },
            selectedItem: null,
            filters: null,
            terms: [],
            selectedFilters: {
                revenue_model: [],
                partners: [],
                legal_structure: [],
                org_form: [],
                theme: [],
                coronavirus_response: []
            },
            isFilterVisible: false,
            isDetails: false,
            taxLabels: [
                "COVID-19 Response",
                "Organizational Form",
                "Legal Structure",
                "Revenue Model",
                "Theme",
            ]
        };
    },
    mounted() {
        this.mapItems = window.mapItems;
        this.unselectAll();
        this.filters = JSON.parse(localStorage.getItem("filters"));
        this.filters.forEach((item, index) => {
          this.filters[index].isVisible = false;
        });

        if (window.slug) {
          this.findSelected(window.slug);
        }
    },
    computed: {
        reorderedFilters() {
            const arr = [];

            if (this.filters) {
                arr.push(this.filters[5]);
                arr.push(this.filters[1]);
                arr.push(this.filters[2]);
                arr.push(this.filters[3]);
                arr.push(this.filters[4]);
            }

            return arr;
        },
        defaultHeader() {
            return {
                "Content-Type": "application/json",
                Accept: "*/*"
            };
        },
        loggedInHeader() {
            let token = localStorage.getItem("token");
            return {
                Authorization: `Bearer ${token}`.trim(),
                "Content-Type": "application/json",
                Accept: "*/*"
            };
        },
        hasAnyFilters() {
            return Object.values(this.selectedFilters).some(
                item => item.length > 0
            );
        },
        filterCount() {
            let c = 0;
            if (this.hasAnyFilters) {
                Object.values(this.selectedFilters).forEach(item => {
                    c += item.length;
                });
            }
            return c;
        },
        filteredMapItems() {
            // let flist = Object.values(this.selectedFilters);
            let filetered = [];
            if (this.activeFilters.length == 0) return this.mapItems;
            let matchCount = this.activeFilters.length;

            this.mapItems.forEach(item => {
                let itemCount = 0;
                this.activeFilters.forEach(key => {
                    let match = this.selectedFilters[key].some(term =>
                        item[key].includes(term)
                    );
                    if (match) itemCount++;
                });
                if (matchCount === itemCount) {
                    filetered.push(item);
                }
            });

            return filetered;
        },
        activeFilters() {
            let f = [];
            Object.keys(this.selectedFilters).forEach(filter => {
                if (this.selectedFilters[filter].length > 0) {
                    f.push(filter);
                }
            });

            return f;
        },
        selectedTerms(term) {
            let slugs = [];
            this.activeFilters.forEach(tax => {
                slugs = slugs.concat(this.selectedFilters[tax]);
            });
            let arr = [];

            slugs.forEach(term => {
                this.filters.forEach(tax => {
                    tax.terms.forEach(t => {
                        if (term === t.slug) {
                            arr.push(t);
                        }
                    });
                });
            });

            return arr;
        },
        selectedItemOrgNames() {
            let ret = [];
            if (this.selectedItem) {
                this.selectedItem.org_form.forEach(slug => {
                    ret.push(this.findTermObjectBySlug(slug).name);
                });
            }
            return ret;
        },
        isMobile() {
            let agents = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            return agents.test(navigator.userAgent);
        },
        allTerms() {
            let arr = [];
            let slugs = [];
            slugs.push(this.selectedItem.coronavirus_response);
            slugs.push(this.selectedItem.org_form);
            slugs.push(this.selectedItem.legal_structure);
            slugs.push(this.selectedItem.revenue_model);
            slugs.push(this.selectedItem.theme);

            slugs.forEach((taxlist,a) => {
                taxlist.forEach((term,b) => {
                    this.filters.forEach(tax => {
                        tax.terms.forEach(t => {
                            if (term === t.slug) {
                                slugs[a][b] = t.name
                            }
                        });
                    });
                });
            });

            return slugs;
        }
    },
    methods: {
        findSelected(slug) {
            this.selectedItem = this.filteredMapItems.find((item, index) => {
                if (item.slug === slug) {
                    this.filteredMapItems[index].isSelected = true;
                    this.$forceUpdate();
                    return true;
                }
            });
        },
        clearAll() {
            this.reorderedFilters.forEach(item => (item.isVisible = false));
            Object.keys(this.selectedFilters).forEach(key => {
                this.selectedFilters[key] = [];
            });
        },
        handleFilterClick(index) {
            console.log(index);
            this.reorderedFilters.forEach(item => (item.isVisible = false));
            this.reorderedFilters[index].isVisible = !this.reorderedFilters[
                index
            ].isVisible;
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
        findTermObjectBySlug(slug) {
            let ret = {};
            this.filters.forEach(tax => {
                tax.terms.forEach(t => {
                    if (slug === t.slug) {
                        ret = t;
                    }
                });
            });

            return ret;
        },
        imagesUrlBase() {
          return `${window.location.origin}/wp-content/themes/rcolab/dist/csi/`;
        },
        getIcon(item) {
            if (item.isSelected) {
                return this.markerUrl('selected');
            } else if (item.coronavirus_response.length) {
                return this.markerUrl('coronavirus');
            }

            return this.markerUrl('normal');
        },
        unselectAll() {
            this.mapItems.forEach(item => (item.isSelected = false));
        },
        handleMarker(item) {
            this.unselectAll();
            const overlayImage = document.querySelector('.overlay img');
            if (overlayImage) {
              overlayImage.src = '';
              overlayImage.src = item.thumbnail;
            }

            item.isSelected = true;
            this.selectedItem = item;
        },
        handleDetails(event) {
            this.isDetails = true;

            event.preventDefault();
            document.querySelector('#case-study').scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        handleClose() {
            this.selectedItem.isSelected = false;
            this.selectedItem = null;
        },
        stripHtml(string) {
          return string.replace(/<\/?[^>]+>/ig, " ").trim();
        },
        markerUrl(markerName) {
          return `${this.imagesUrlBase()}${MARKER_PREFIX}${markerName}.svg`;
        },
        clusterStyle(markerName) {
          return {
            anchorText: [-2, 0],
            height: 33,
            textColor: 'black',
            url: this.markerUrl(markerName),
            width: 29,
          };
        },
        clusterStyles() {
          var styles = [];

          for (const marker of MARKERS) {
            styles.push(this.clusterStyle(marker));
          }

          return styles;
        },
        calculateClusterMarker(clusterMarkers, numberOfClusterStyles) {
          const uniqueMarkersInCluster = [];

          for (const clusterMarker of clusterMarkers) {
            const markerName = clusterMarker.icon.substring(clusterMarker.icon.lastIndexOf(MARKER_PREFIX) + MARKER_PREFIX.length).split('.').slice(0, -1).join('.');

            if (!uniqueMarkersInCluster.includes(markerName)) {
              uniqueMarkersInCluster.push(markerName);
            }
          }

          uniqueMarkersInCluster.sort()
          const clusterMarkerToUse = uniqueMarkersInCluster.join('_');

          return {
            index: MARKERS.indexOf(clusterMarkerToUse) + 1,
            text: clusterMarkers.length,
          };
        },
        getRandomInteger(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);

            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }
};
</script>
<style>
.caps {
    text-transform: uppercase;
}
</style>