var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-template" }, [
    _c(
      "div",
      { staticClass: "submission-form" },
      [
        !_vm.isActive
          ? _c(
              "a",
              {
                staticClass: "button",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.isActive = true
                  }
                }
              },
              [_vm._v("Click here to submit a case study")]
            )
          : [
              _c("p", [_vm._v("All fields are required")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "columns medium-4 small-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payload.fullName,
                        expression: "payload.fullName"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "Full Name",
                      required: ""
                    },
                    domProps: { value: _vm.payload.fullName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.payload, "fullName", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-4 small-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payload.email,
                        expression: "payload.email"
                      }
                    ],
                    attrs: {
                      type: "email",
                      placeholder: "Email",
                      required: ""
                    },
                    domProps: { value: _vm.payload.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.payload, "email", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-4 small-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payload.org,
                        expression: "payload.org"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "Name of Initiative",
                      required: ""
                    },
                    domProps: { value: _vm.payload.org },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.payload, "org", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns small-12" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payload.projectDescription,
                        expression: "payload.projectDescription"
                      }
                    ],
                    attrs: {
                      cols: "30",
                      rows: "10",
                      placeholder: "Description"
                    },
                    domProps: { value: _vm.payload.projectDescription },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.payload,
                          "projectDescription",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { disabled: !_vm.isValid },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.sendMail($event)
                        }
                      }
                    },
                    [_vm._v("Submit")]
                  )
                ])
              ])
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }