var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-template" }, [
    _c("div", { staticClass: "row full-width" }, [
      _c("div", { staticClass: "small-12 medium-8 large-9 columns" }, [
        _c(
          "div",
          { staticClass: "relative-scope" },
          [
            _c(
              "GmapMap",
              {
                staticStyle: { width: "100%", height: "80vh" },
                attrs: {
                  center: { lat: 31.1961, lng: 16.583862 },
                  zoom: 2,
                  "map-type-id": "terrain",
                  width: "100%",
                  options: _vm.mapOptions
                }
              },
              [
                _c(
                  "GmapCluster",
                  {
                    attrs: {
                      calculator: this.calculateClusterMarker,
                      gridSize: 10,
                      styles: this.clusterStyles(),
                      zoomOnClick: true
                    }
                  },
                  _vm._l(_vm.filteredMapItems, function(m, index) {
                    return _c("GmapMarker", {
                      key: index,
                      attrs: {
                        position: m.position,
                        clickable: true,
                        draggable: false,
                        icon: _vm.getIcon(m)
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleMarker(m)
                        }
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.selectedItem
              ? _c("div", { staticClass: "overlay" }, [
                  _c(
                    "a",
                    {
                      staticClass: "close",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.handleClose($event)
                        }
                      }
                    },
                    [_vm._v("×")]
                  ),
                  _vm._v(" "),
                  _c("h4", {
                    domProps: { innerHTML: _vm._s(_vm.selectedItem.title) }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "meta map-options-title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.selectedItem.city) +
                        ", " +
                        _vm._s(_vm.selectedItem.country) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.selectedItem.thumbnail
                    ? _c("img", {
                        attrs: {
                          alt: _vm.selectedItem.title,
                          src: _vm.selectedItem.thumbnail
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.stripHtml(
                    _vm.selectedItem.coronavirus_response_description
                  )
                    ? _c("p", {
                        staticClass: "small",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.selectedItem.coronavirus_response_description
                          )
                        }
                      })
                    : _c("p", {
                        staticClass: "small",
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedItem.narrative)
                        }
                      }),
                  _vm._v(" "),
                  _c("p", { staticClass: "small" }, [
                    _vm._m(0),
                    _vm._v(": "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.selectedItemOrgNames.join(", "))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button secondary",
                      attrs: { href: "#case-study" },
                      on: { click: _vm.handleDetails }
                    },
                    [_vm._v("Details")]
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "small-12 medium-4 large-3 columns" }, [
        _c(
          "div",
          { staticClass: "filters" },
          [
            _vm.hasAnyFilters
              ? _c(
                  "h6",
                  {
                    staticClass: "filter-title flex flex-aicenter",
                    staticStyle: { "padding-left": "1rem" },
                    on: { click: _vm.clearAll }
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("close")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "Clear All Filters (" + _vm._s(_vm.filterCount) + ")"
                      )
                    ])
                  ]
                )
              : _c(
                  "h6",
                  {
                    staticClass: "filter-title flex flex-aicenter",
                    staticStyle: { "padding-left": "1rem" }
                  },
                  [_c("span", [_vm._v("Filters")])]
                ),
            _vm._v(" "),
            _vm._l(_vm.reorderedFilters, function(filter, index) {
              return _c(
                "section",
                { key: filter.name + index, staticClass: "filter" },
                [
                  _c(
                    "h6",
                    {
                      staticClass:
                        "map-options-title flex flex-aicenter map-options-title-filter map-options-title-filter-normal",
                      class: {
                        "map-options-title-filter-coronavirus":
                          filter.slug === "coronavirus_response"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.handleFilterClick(index)
                        }
                      }
                    },
                    [
                      filter.isVisible
                        ? [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("remove")
                            ])
                          ]
                        : [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("add")
                            ])
                          ],
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(filter.name))])
                    ],
                    2
                  ),
                  _vm._v(" "),
                  filter.isVisible
                    ? _c(
                        "div",
                        { staticClass: "hidden" },
                        _vm._l(filter.terms, function(term) {
                          return _c(
                            "div",
                            { key: term.slug, staticClass: "term" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedFilters[filter.slug],
                                    expression: "selectedFilters[filter.slug]"
                                  }
                                ],
                                attrs: { type: "checkbox", id: term.slug },
                                domProps: {
                                  value: term.slug,
                                  checked: Array.isArray(
                                    _vm.selectedFilters[filter.slug]
                                  )
                                    ? _vm._i(
                                        _vm.selectedFilters[filter.slug],
                                        term.slug
                                      ) > -1
                                    : _vm.selectedFilters[filter.slug]
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.selectedFilters[filter.slug],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = term.slug,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.selectedFilters,
                                            filter.slug,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.selectedFilters,
                                            filter.slug,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.selectedFilters,
                                        filter.slug,
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", {
                                attrs: { for: term.slug },
                                domProps: { innerHTML: _vm._s(term.name) }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "row full-width-inner-content",
        attrs: { id: "case-study" }
      },
      [
        _vm.isDetails && _vm.selectedItem
          ? _c("div", { staticClass: "flex flex-nowrap" }, [
              _c("div", { staticClass: "pull copy" }, [
                _c("h2", {
                  domProps: { innerHTML: _vm._s(_vm.selectedItem.title) }
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedItem.narrative_full)
                  }
                }),
                _vm._v(" "),
                _vm.stripHtml(
                  _vm.selectedItem.coronavirus_response_description_full
                )
                  ? _c("div", [
                      _c("h3", { staticClass: "map-options-title" }, [
                        _vm._v("COVID-19 Response")
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.selectedItem
                              .coronavirus_response_description_full
                          )
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h3", { staticClass: "map-options-title" }, [
                  _vm._v("Theory of Change")
                ]),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.selectedItem.theory_of_change)
                  }
                }),
                _vm._v(" "),
                _c("h3", { staticClass: "map-options-title" }, [
                  _vm._v("Activities")
                ]),
                _vm._v(" "),
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.selectedItem.activities) }
                }),
                _vm._v(" "),
                _c("h3", { staticClass: "map-options-title" }, [
                  _vm._v("Results")
                ]),
                _vm._v(" "),
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.selectedItem.results) }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pull meta intro", staticStyle: { order: "0" } },
                [
                  _c(
                    "ul",
                    [
                      _c("li", [
                        _c("h3", { staticClass: "map-options-title" }, [
                          _vm._v("Location")
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("h6", [
                            _vm._v(
                              _vm._s(_vm.selectedItem.city) +
                                ", " +
                                _vm._s(_vm.selectedItem.country)
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.selectedItem.website
                        ? _c("li", [
                            _c("h3", { staticClass: "map-options-title" }, [
                              _vm._v("Website")
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("h6", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "href",
                                    attrs: {
                                      href: _vm.selectedItem.website,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("Visit now")]
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedItem.facebook ||
                      _vm.selectedItem.twitter ||
                      _vm.selectedItem.linkedin
                        ? _c("li", [
                            _c("h3", { staticClass: "map-options-title" }, [
                              _vm._v("Social Media")
                            ]),
                            _vm._v(" "),
                            _vm.selectedItem.facebook
                              ? _c("div", [
                                  _c("h6", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "href",
                                        attrs: {
                                          href:
                                            "https://facebook.com/" +
                                            _vm.selectedItem.facebook,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("Facebook")]
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedItem.instagram
                              ? _c("div", [
                                  _c("h6", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "href",
                                        attrs: {
                                          href:
                                            "https://instagram.com/" +
                                            _vm.selectedItem.instagram,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("Instagram")]
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedItem.twitter
                              ? _c("div", [
                                  _c("h6", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "href",
                                        attrs: {
                                          href:
                                            "https://twitter.com/" +
                                            _vm.selectedItem.twitter,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("Twitter")]
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedItem.linkedin
                              ? _c("div", [
                                  _c("h6", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "href",
                                        attrs: {
                                          href:
                                            "https://www.linkedin.com/company/" +
                                            _vm.selectedItem.linkedin,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("LinkedIn")]
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedItem.founded_at
                        ? _c("li", [
                            _c("h3", { staticClass: "map-options-title" }, [
                              _vm._v("Year of Founding")
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("h6", [
                                _vm._v(_vm._s(_vm.selectedItem.founded_at))
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedItem.profile
                        ? _c("li", [
                            _c("h3", { staticClass: "map-options-title" }, [
                              _vm._v("Profile")
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("h6", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: _vm.selectedItem.profile
                                    }
                                  },
                                  [_vm._v("Read article")]
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.allTerms, function(item, index) {
                        return _vm.allTerms
                          ? [
                              item.length > 0
                                ? _c(
                                    "li",
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "map-options-title" },
                                        [_vm._v(_vm._s(_vm.taxLabels[index]))]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(item, function(term, indx) {
                                        return _c("span", {}, [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(term)
                                            }
                                          }),
                                          indx < item.length - 1
                                            ? _c("span", [_vm._v(", ")])
                                            : _vm._e()
                                        ])
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("li", [
                        _c("h3", { staticClass: "map-options-title" }, [
                          _vm._v("Share This Case Study")
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("h6", [
                            _c("a", {
                              attrs: {
                                target: "_blank",
                                href: _vm.selectedItem.link
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.selectedItem.title)
                              }
                            })
                          ])
                        ])
                      ])
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      attrs: {
                        href: "#map",
                        onclick:
                          "document.querySelector('#map').scrollIntoView({ behavior: 'smooth', block: 'start' }); return false;"
                      }
                    },
                    [_vm._v("Back to the map")]
                  )
                ]
              )
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "caps" }, [
      _c("strong", [_vm._v("Organizational Form")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }